import $ from "jquery";

export const formUploadFile = () => {
  const fileInputs = $(".js-form-file");
  fileInputs.find("input").on("change", function () {
    const file = $(this).prop("files")[0];
    console.log(file);
    $(this).parent().parent().next().text(file.name);
  });
};
