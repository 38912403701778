import $ from "jquery";

export const selectPlaceholder = () => {
  const select = $(".js-select");
  const placeholderClass = "is-placeholder";
  // is-emptyクラスを付与

  select.addClass(placeholderClass);

  // selectのoptionを切り替え時
  select.on("change", function () {
    // option選択時
    if ($(this).val() !== "") {
      // is-emptyクラスを削除
      $(this).removeClass(placeholderClass);
    }
    // placeholder選択時
    else {
      // is-emptyクラスを付与
      $(this).addClass(placeholderClass);
    }
  });
};
