import $ from "jquery";
import { gsap } from "gsap";

export const mouseStalker = () => {
  const cursor = $("#cursor");
  const delayCursor = $("#delay-cursor");
  const footerNextEl = document.querySelector(".js-footer-next-nav");
  const contactBannerEl = document.querySelector(".js-contact-banner");
  const topNavLinks = document.querySelectorAll(".js-top-nav");
  const daySliderPrev = document.querySelectorAll(".js-day-slider-prev");
  const daySliderNext = document.querySelectorAll(".js-day-slider-next");
  const cursorText = $(".js-cursor-text");
  const cursorEmoji = $(".js-cursor-emoji");

  // カーソル要素の座標
  const pos = { x: 0, y: 0 };
  // マウスカーソルの座標
  const mouse = { x: pos.x, y: pos.y };
  // 0.01〜1 数値が大きほど早い
  const speed = 0.5;

  // カーソルの座標を設定する
  var cursorSetX = gsap.quickSetter(cursor, "x", "px");
  var cursorSetY = gsap.quickSetter(cursor, "y", "px");

  // マウスカーソル座標を取得する
  document.addEventListener("mousemove", function (event) {
    mouse.x = event.pageX;
    mouse.y = event.pageY;
  });

  gsap.ticker.add(function () {
    var dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

    pos.x += (mouse.x - pos.x) * dt;
    pos.y += (mouse.y - pos.y) * dt;
    cursorSetX(pos.x);
    cursorSetY(pos.y);

    gsap.set(cursor, {
      css: {
        transform: `${pos.x}px ${pos.y}px`,
      },
    });
  });

  gsap.ticker.add(function () {
    var dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

    pos.x += (mouse.x - pos.x) * dt;
    pos.y += (mouse.y - pos.y) * dt;
    const clipPathCoor = `ellipse(12rem 12rem at ${pos.x}px ${pos.y}px)`;

    gsap.set(delayCursor, {
      css: {
        clipPath: clipPathCoor,
      },
    });
  });

  const createCursor = (text, emoji, activeClass = "is-active") => {
    $("html, body").addClass("is-cursor-disabled");
    cursor.addClass(activeClass);
    cursorText.html(text);
    cursorEmoji.text(emoji);
  };

  const initCursor = (activeClass = "is-active") => {
    $("html, body").removeClass("is-cursor-disabled");
    cursor.removeClass(activeClass);
    cursorText.html("");
    cursorEmoji.text("");
  };

  if (topNavLinks.length !== 0) {
    topNavLinks.forEach((link) => {
      link.addEventListener("mouseenter", (e) => {
        const navType = $(e.target).data("type");

        if (navType === "business") {
          createCursor("OUR BUSINESS", "👩‍💻");
        } else if (navType === "7days") {
          createCursor("7Days<br>of Fundoshi", "📅");
        } else if (navType === "careers") {
          createCursor("CAREERS", "✊");
        } else if (navType === "about") {
          createCursor("ABOUT US", "🏖️");
        } else if (navType === "news") {
          createCursor("NEWS", "💌");
        }
      });

      link.addEventListener("mouseleave", () => {
        initCursor();
      });
    });
  }

  // footer next
  footerNextEl.addEventListener("mouseenter", (e) => {
    const navType = $(e.target).data("type");

    if (navType === "business") {
      createCursor("OUR BUSINESS", "👉");
    } else if (navType === "7days") {
      createCursor("7Days<br>of Fundoshi", "👉");
    } else if (navType === "careers") {
      createCursor("CAREERS", "👉");
    } else if (navType === "about") {
      createCursor("ABOUT US", "👉");
    } else if (navType === "top") {
      createCursor("TOP", "👉");
    }
  });

  footerNextEl.addEventListener("mouseleave", () => {
    initCursor();
  });

  // contact banner
  if ($(contactBannerEl).length !== 0) {
    contactBannerEl.addEventListener("mouseenter", (e) => {
      $("html, body").addClass("is-cursor-disabled");
      cursor.addClass("is-contact-active");
      cursorEmoji.text("📧");
      cursorText.text("CONTACT");

      delayCursor.addClass("active");
    });

    contactBannerEl.addEventListener("mouseleave", () => {
      $("html, body").removeClass("is-cursor-disabled");
      cursor.removeClass("is-contact-active");
      cursorText.text("");
      cursorEmoji.text("");

      delayCursor.removeClass("active");
    });
  }

  // day slider
  daySliderPrev.forEach((el) => {
    el.addEventListener("mouseenter", () => {
      createCursor("PREV", "👈", "is-active is-day-slider-active");
    });

    el.addEventListener("mouseleave", () => {
      initCursor("is-active is-day-slider-active");
    });
  });

  daySliderNext.forEach((el) => {
    el.addEventListener("mouseenter", () => {
      createCursor("NEXT", "👉", "is-active is-day-slider-active");
    });

    el.addEventListener("mouseleave", () => {
      initCursor("is-active is-day-slider-active");
    });
  });
};
