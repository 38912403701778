import $ from "jquery";
import inView from "in-view";

export const inview = () => {
  inView(".inview").on("enter", function (el) {
    el.classList.add("inview-is-visible");
  });

  inView(".inview-custom").on("enter", function (el) {
    el.classList.add("inview-is-visible");
  });

  inView(".inview-slide-up").on("enter", function (el) {
    el.classList.add("inview-is-visible");
  });

  if ($(".inview-slide-up-group")) {
    const groups = $(".inview-slide-up-group");
    groups.each(function (_, group) {
      $(group)
        .find(".inview-slide-up-el")
        .each(function (i, el) {
          $(el).css("transition-delay", `${0.2 + i * 0.2}s`);
        });
    });
  }

  inView(".inview-slide-up-group").on("enter", function (el) {
    el.classList.add("inview-is-visible");
  });
};
