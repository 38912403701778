import $ from "jquery";

export const hamburgerMenu = () => {
  const menuList = $(".js-hamburger-menu-list");
  const menuItems = menuList.children("li");

  const openMenu = () => {
    menuItems.each(function (i, item) {
      $(item).css("transition-delay", `${i * 0.06}s`);
    });

    $("body").addClass("is-hamburger-menu-bg-expanded");

    setTimeout(() => {
      $("body").addClass("is-hamburger-menu-logo-visible");
    }, 100);

    setTimeout(() => {
      $("body").addClass("is-hamburger-menu-link-visible");
    }, 200);

    setTimeout(() => {
      $("body").addClass("is-hamburger-menu-sub-visible");
    }, 300);
  };

  const closeMenu = () => {
    menuItems.each(function (i, item) {
      $(item).css("transition-delay", `0s`);
    });

    $("body").removeClass("is-hamburger-menu-sub-visible");

    setTimeout(() => {
      $("body").removeClass("is-hamburger-menu-link-visible");
    }, 200);

    setTimeout(() => {
      $("body").removeClass("is-hamburger-menu-logo-visible");
    }, 300);

    setTimeout(() => {
      $("body").removeClass("is-hamburger-menu-bg-expanded");
    }, 400);
  };

  $("#hamburger-btn").on("click", function () {
    // ハンバーガーメニューが開いているとき
    if ($("body").hasClass("is-hamburger-menu-open")) {
      $("body").removeClass("is-hamburger-menu-open");

      closeMenu();
    }
    // ハンバーガーメニューが閉じているとき
    else {
      $("body").addClass("is-hamburger-menu-open");

      openMenu();
    }
  });
};
