class InertiaPosition {
    constructor(x,y,opt) {

        this._opt = {
            ...opt
        };
        
        this.K = this._opt?.K || 0.1; // or 
        this.D = this._opt?.D || 0.8; // 0.9 or 0.3

        this._current = {
            x: x || 0,
            y: y || 0
        };

        this._velocity = {
            x: 0,
            y: 0
        }

        this._target = {
            x: x || 0,
            y: y || 0
        }

    }

    get current() {
        return this._current;
    }

    get target() {
        return this._target;
    }

    set current(value) {
        this._current = value;        
    }

    set target(value) {
        this._target = value;
    }

    // please call this method in update method
    // todo : add timeScale
    update(timeScale = 1) {        

        const K = this.K;
        const D = this.D;

        const X = {
            x: this._current.x - this._target.x,
            y: this._current.y - this._target.y
        };

        if(Math.abs(X.x) < 0.00001 && Math.abs(X.y) < 0.00001) {            
            return {
                x: this._target.x,
                y: this._target.y
            };
        }

        const A = {
            x: X.x * -K,
            y: X.y * -K
        };

        // set velocity
        this._velocity.x += A.x;
        this._velocity.y += A.y;

        // set position 
        this._current.x += this._velocity.x;
        this._current.y += this._velocity.y;

        // set damping
        this._velocity.x *= D;
        this._velocity.y *= D;       
        
        return this._current;
        
    }

    setTarget(x,y) {
        this._target.x = x;
        this._target.y = y;
    }

}

export default InertiaPosition;