import * as THREE from "three";
import {
  createPerspectiveCamera,
  createOrthographicCamera,
} from "./createCameraObj";

export default class CanvasBase {
  constructor(el, opt) {
    this.el = el;    
    this.opt = opt || {};
    this.debug = this.opt?.debug || false;
    if (!this.el) return;

    this.state = {
      isPlay: false,
      isSetup: false,
      width: null,
      height: null,
      prevResizeCheck: Date.now(),
      deviceRatio: Math.min(window.devicePixelRatio, 2),
    };

    // opt
    this.checkInterval = 1500;

    // obj
    this.frameObj = null;
    this.clock = null;
  }

  setup() {
    // setup scene
    this.scene = new THREE.Scene();

    // setup clock
    this.clock = new THREE.Clock();

    // get size
    this.updateCanvasSize();
    const w = this.state.width;
    const h = this.state.height;

    console.log(w, h);

    // setup camera
    switch (this.opt?.cameraType) {
      case "orthographic":
        this.cameraObj = createOrthographicCamera(w, h);
        break;
      case "perspective":
        this.cameraObj = createPerspectiveCamera(w, h, 60);
        break;
      default:
        this.cameraObj = createPerspectiveCamera(w, h, 60);
        break;
    }
    this.camera = this.cameraObj.camera;

    // setup renderer
    const clearColor = this.opt?.clearColor || 0x000000;
    const clearAlpha =
      this.opt?.clearAlpha == null || this.opt?.clearAlpha == undefined
        ? 1.0
        : this.opt?.clearAlpha;

    this.renderer = new THREE.WebGLRenderer({
      alpha: this.opt?.alpha || false,
      antialias: this.opt?.antialias || false,
      depth: this.opt?.depth || false,
      stencil: false,
    });

    this.renderer.setClearColor(clearColor, clearAlpha);

    // add to dom
    this.el.appendChild(this.renderer.domElement);

    this.state.isSetup = true;
        
  }
  isCheckResize() {
    const current = Date.now();
    // check interval
    if (current - this.state.prevResizeCheck < this.checkInterval) {
      return false;
    }
    this.state.prevResizeCheck = current;

    // check canvas size
    const rect = this.el.getBoundingClientRect();
    if (this.state.width !== rect.width || this.state.height !== rect.height) {
      return true;
    }

    return false;
  }

  resizeScene() {
    this.updateCanvasSize();
    const w = this.state.width;
    const h = this.state.height;

    this.renderer.setSize(w, h);

    // this.camera.aspect = w / h;
    this.cameraObj.resize(w, h);

    const deviceRatio = Math.min(window.devicePixelRatio, 2);
    this.state.deviceRatio = deviceRatio;
    this.renderer?.setPixelRatio(deviceRatio);   
  }

  updateCanvasSize() {
    const rect = this.el.getBoundingClientRect();
    this.state.width = rect.width;
    this.state.height = rect.height;
    return {
      width: this.state.width,
      height: this.state.height,
    };
  }

  updateState() {
    this.state.time = this.clock.getElapsedTime();
    if (this.isCheckResize()) {
      this.resizeScene();
    }
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  destroy() {
    this.state.isPlay = false;
    this.state.isSetup = false;

    this.el.removeChild(this.renderer.domElement);

    this.frameObj = null;
    this.scene = null;
    this.camera = null;
    this.cameraObj = null;
    this.clock = null;
    this.renderer = null;
  }

  calcScreenCoord(_width,_height) {        
    const width = _width || this.state.width;
    const height = _height || this.state.height;               
    const ratio = this.state.deviceRatio || 1;
    return new THREE.Vector2(width, height).multiplyScalar(ratio);
  }     

  async getTexture(src) {
    const loader = new THREE.TextureLoader();
    return await loader.loadAsync(src);
  }    
}
