import $ from "jquery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

function isMobile() {
  return window.matchMedia("(max-width: 768px)").matches;
}

export const daysMv = () => {
  const section = $(".js-sub-mv");
  const preText = $(".js-sub-mv-pre-text-wrap");
  const text01 = $(".js-pre-text-01");
  const text02 = $(".js-pre-text-02");
  const bottomText = $(".js-pre-bottom-text");

  gsap.to($(text01), {
    ease: "power2.inOut",
    xPercent: -150,
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "+=250",
      scrub: 2,
    },
  });

  gsap.to($(text02), {
    ease: "power2.inOut",
    xPercent: 300,
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "+=250",
      scrub: 2,
      // pin: section,
    },
  });

  gsap.to($(bottomText), {
    ease: "power2.inOut",
    yPercent: 500,
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "+=250",
      scrub: 2,
    },
  });
};
