import CanvasBase from "../webgl/canvasBase";
import LimitFrameRate from "../webgl/limitFrameRate";
// import { isMobile } from "../webgl/canvasUtil";
import * as THREE from "three";

// import { sRGBEncoding } from "three";

import InertiaPosition from "../lib/inertiaPosition";

import { src as vertShader } from "../glsl/topMvCanvas/vert";
import { src as fragShader } from "../glsl/topMvCanvas/frag";

import { src as textVertShader } from "../glsl/topMvCanvas/textVert";
import { src as textFragShader } from "../glsl/topMvCanvas/textFrag";
import { isMobile } from "../webgl/canvasUtil";

// PC 画像比率
// 1976 x 1222

// SP 画像比率
// 335 x 770

class Canvas extends CanvasBase {
  constructor(el, opt) {
    super(el, opt);
    this.internalPosition = new InertiaPosition(0, 0, {
      K: 0.12,
      D: 0.15,
    });
  }
  createMesh() {
    this.rendererTarget = new THREE.WebGLRenderTarget(
      this.state.width,
      this.state.height
    );
    this.createPlane();
    this.createText();
    this.createTextBg();
  }

  createText() {
    let width = this.state.width;
    let height = width / (1440 / 780);
    const texture = this.text;

    if (isMobile()) {
      width = this.state.width;
      height = width / (375 / 812);
    }

    const centerX =
      (isMobile()
        ? Number(this.el.dataset.centerSpX)
        : Number(this.el.dataset.centerX)) || 0;
    const centerY =
      (isMobile()
        ? Number(this.el.dataset.centerSpY)
        : Number(this.el.dataset.centerY)) || 0;

    const g = new THREE.PlaneGeometry(1, 1, 1, 1);
    const m = new THREE.ShaderMaterial({
      vertexShader: textVertShader,
      fragmentShader: textFragShader,
      transparent: true,
      uniforms: {
        uResolution: {
          value: [this.state.width, this.state.height],
        },
        uScreenCoord: { value: this.calcScreenCoord() },
        uTexture: {
          value: {
            data: texture,
            uvScale: this.calcCoveredTextureScale(texture, width / height),
          },
        },
        uBg: {
          value: {
            data: null,
          },
        },
      },
    });
    this.textWrap = new THREE.Group();
    this.textMesh = new THREE.Mesh(g, m);

    this.textMesh.scale.set(width, height, 1);

    // modify position
    this.textMesh.position.set(width * centerX, height * centerY, 0);
    this.textWrap.add(this.textMesh);
    this.textWrap.position.set(width * -centerX, height * -centerY, 0);

    this.scene.add(this.textWrap);
  }

  createTextBg() {
    let width = this.state.width;
    let height = width / (1440 / 780);
    const texture = this.textBgSrc;
    texture.encoding = THREE.sRGBEncoding;

    if (isMobile()) {
      width = this.state.width;
      height = width / (375 / 812);
    }

    const centerX =
      (isMobile()
        ? Number(this.el.dataset.centerSpX)
        : Number(this.el.dataset.centerX)) || 0;
    const centerY =
      (isMobile()
        ? Number(this.el.dataset.centerSpY)
        : Number(this.el.dataset.centerY)) || 0;

    const g = new THREE.PlaneGeometry(1, 1, 1, 1);
    const m = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      antiAlias: true,
    });
    this.textBgWrap = new THREE.Group();
    this.textBgMesh = new THREE.Mesh(g, m);

    this.textBgMesh.scale.set(width, height, 1);

    // modify position
    this.textBgMesh.position.set(width * centerX, height * centerY, 0);
    this.textBgWrap.add(this.textBgMesh);
    this.textBgWrap.position.set(width * -centerX, height * -centerY, 0);
    this.textBgWrap.position.z = -1;

    this.scene.add(this.textBgWrap);
  }

  createPlane() {
    const texture = this.texture;
    const aspectRatio = !isMobile() ? 1976 / 1222 : 335 / 770;

    let width = this.state.width;
    let height = width * aspectRatio;

    if (this.state.height > height) {
      height = this.state.height;
      width = height * aspectRatio;
    }

    const g = new THREE.PlaneGeometry(1, 1, 1, 1);
    const m = new THREE.ShaderMaterial({
      // side: THREE.DoubleSide,
      vertexShader: vertShader,
      fragmentShader: fragShader,
      uniforms: {
        uColor: { value: new THREE.Color(0x171717) },
        uTexture: {
          value: {
            data: texture,
            uvScale: this.calcCoveredTextureScale(texture, width / height),
          },
        },
        uPictureOpacity: {
          value: 1.0,
        },
        uMosaicPower: {
          value: 1000.0, // 10 - 1000
        },
      },
    });
    this.box = new THREE.Mesh(g, m);
    this.box.scale.set(width, height, 1);
    this.scene.add(this.box);
  }

  update() {
    const current = this.internalPosition.update();

    const degree = current.x;

    let mosaicPower = Math.round(degree * 100) / 100;
    mosaicPower = mosaicPower >= 0.3 ? 1 : mosaicPower / 0.3;
    // console.log(mosaicPower);
    this.box.material.uniforms.uMosaicPower.value = mosaicPower * 200 + 3;
    // console.log(this.box.material.uniforms.uMosaicPower.value);

    const textScale = 1.0 + degree * 80;
    this.textWrap.scale.set(textScale, textScale, 1);
    this.textBgWrap.scale.set(textScale, textScale, 1);

    this.textMesh.visible = false;
    this.box.visible = true;

    this.renderer.setRenderTarget(this.rendererTarget);
    this.render();

    this.textMesh.material.uniforms.uBg.value = this.rendererTarget.texture;
    this.renderer.setRenderTarget(null);

    this.textMesh.visible = true;
    this.box.visible = false;
  }

  resizeScene() {
    super.resizeScene();

    const { width, height } = this.state;
    this.rendererTarget.setSize(width, height);

    // resize text
    {
      let width = this.state.width;
      let height = width / (1440 / 780);
      const texture = this.text;

      if (isMobile()) {
        width = this.state.width;
        height = width / (375 / 812);
      }

      const centerX =
        (isMobile()
          ? Number(this.el.dataset.centerSpX)
          : Number(this.el.dataset.centerX)) || 0;
      const centerY =
        (isMobile()
          ? Number(this.el.dataset.centerSpY)
          : Number(this.el.dataset.centerY)) || 0;

      this.textMesh.material.uniforms.uResolution.value = [
        this.state.width,
        this.state.height,
      ];
      this.textMesh.material.uniforms.uScreenCoord.value =
        this.calcScreenCoord();
      this.textMesh.material.uniforms.uTexture.value.uvScale =
        this.calcCoveredTextureScale(texture, width / height);

      this.textMesh.scale.set(width, height, 1);

      this.textMesh.position.set(width * centerX, height * centerY, 0);
      this.textWrap.position.set(width * -centerX, height * -centerY, 0);

      this.textBgMesh.scale.set(width, height, 1);
      this.textBgMesh.position.set(width * centerX, height * centerY, 0);
      this.textBgWrap.position.set(width * -centerX, height * -centerY, 0);
      this.textBgWrap.position.z = -1;
    }
    // resize plane
    {
      const texture = this.texture;
      const aspectRatio = !isMobile() ? 1976 / 1222 : 335 / 770;

      let width = this.state.width;
      let height = width / aspectRatio;

      if (this.state.height > height) {
        height = this.state.height;
        width = height * aspectRatio;
      }

      this.box.material.uniforms.uTexture.value.uvScale =
        this.calcCoveredTextureScale(texture, width / height);
      this.box.position.set(0, 0, 0);
      this.box.scale.set(width, height, 1);
    }
  }

  async setup({ picSrc, textSrc, textBgSrc }) {
    super.setup();
    if (!picSrc) throw new Error("picSrc is not defined");
    this.texture = await this.getTexture(picSrc);
    console.log("texture done");
    if (!textSrc) throw new Error("textSrc is not defined");
    this.text = await this.getTexture(textSrc);
    console.log("text done");
    if (!textBgSrc) throw new Error("textBgSrc is not defined");
    this.textBgSrc = await this.getTexture(textBgSrc);
    console.log("textBg done");
    this.init();
  }
  init() {
    this.createMesh();
  }
  frame(timestamp) {
    if (this.limitFrameRate.isLimitFrames(timestamp)) {
      this.frameObj.init();
      return;
    }

    this.updateState();
    this.update();
    this.render();

    this.frameObj.init();
  }
  play() {
    const _this = this;
    if (!this.state.isSetup && this.state.isPlay) return;
    this.state.isPlay = true;
    this.resizeScene();
    this.updateState();
    this.update();

    this.limitFrameRate = new LimitFrameRate(this.opt.fps);

    this.frameObj = {
      id: null,
      init: function () {
        this.id = requestAnimationFrame(_this.frame.bind(_this));
      },
    };

    this.frameObj.init();
  }
  pause() {
    if (!this.state.isPlay) return;
    this.state.isPlay = false;
    cancelAnimationFrame(this.frameObj.id);
    this.frameObj = null;
  }
  destroy() {
    this.pause();
    super.destroy();
  }

  calcCoveredTextureScale(texture, aspect) {
    const result = new THREE.Vector2();
    const imageAspect = texture.source.data.width / texture.source.data.height;
    if (aspect < imageAspect) {
      result.set(aspect / imageAspect, 1);
    } else {
      result.set(1, imageAspect / aspect);
    }
    return [result.x, result.y];
  }
}

export default Canvas;
