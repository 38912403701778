import $ from "jquery";
import Lenis from "@studio-freight/lenis";

import { infiniteScroll } from "./modules/infiniteScroll";
import { subPageMv } from "./modules/subPageMv";
import { topPageMv } from "./modules/topPageMv";
import { selectPlaceholder } from "./modules/selectPlaceholder";
import { formUploadFile } from "./modules/formUploadFile";
import { careersBgText } from "./modules/careersBgText";
import { mouseStalker } from "./modules/mouseStalker";
import { daySlider } from "./modules/daySlider";
import { hamburgerMenu } from "./modules/hamburgerMenu";
import { jobAccordion } from "./modules/jobAccordion";
import { formScrollToError } from "./modules/formScrollToError";
import { inview } from "./modules/inview";
import { Content } from "./modules/animatePixels";
import { aboutMv } from "./modules/mv/aboutMv";
import { daysMv } from "./modules/mv/daysMv";
import { reload } from "./modules/reload";
import { businessMv } from "./modules/mv/businessMv";
import { careersMv } from "./modules/mv/careersMv";
import { entryFormSelect } from "./modules/entryFormSelect";

$(function () {
  reload();
  hamburgerMenu();
  infiniteScroll();
  subPageMv();
  careersBgText();
  mouseStalker();
  daySlider();
  jobAccordion();
  inview();

  if ($(".js-front-page").length !== 0) {
    topPageMv();
    // loading start
    imagesProgress();
    function imagesProgress() {
      $("body").addClass("is-loading");
      const container = $("#loading");
      const progressBar = container.find("#loading-bar");
      const count = $("#loading-count");
      const imgLoad = imagesLoaded("body");
      const imgTotal = imgLoad.images.length;
      let imgLoaded = 0;
      let current = 0;
      let progressTimer = setInterval(updateProgress, 1000 / 60);
      imgLoad.on("progress", function () {
        imgLoaded++;
      });

      // emoji
      const emoji = $("#loading-emoji");
      const emojis = ["🍻", "🥳", "🎉", "😍"];
      let emojiIndex = 0;
      function changeEmoji() {
        if (emojiIndex == 4) {
          emojiIndex = 0;
        }

        $(emoji).text(emojis[emojiIndex]);
        emojiIndex++;
        console.log(emojiIndex);
      }
      let emojiTimer = setInterval(changeEmoji, 1000);

      function updateProgress() {
        var target = (imgLoaded / imgTotal) * 100;
        current += (target - current) * 0.1;
        progressBar.css({ width: current + "%" });
        count.text(Math.floor(current));
        if (current >= 100) {
          clearInterval(progressTimer);
          clearInterval(emojiTimer);
          $("body").removeClass("is-loading");
          progressBar.delay(500).animate({ opacity: 0 }, 250, function () {
            container.animate({ opacity: "0" }, 500);
          });
        }
        if (current > 99.9) {
          current = 100;
        }
      }
    }
    // loading end
  }

  if ($(".js-page-about").length !== 0) {
    aboutMv();
  }

  if ($(".js-page-days").length !== 0) {
    daysMv();
  }

  if ($(".js-page-business").length !== 0) {
    businessMv();
  }

  if ($(".js-page-careers").length !== 0) {
    careersMv();
  }

  if ($(".js-img-pixelated").length !== 0) {
    const pixelImgs = document.querySelectorAll(".js-img-pixelated");
    pixelImgs.forEach((img) => {
      new Content(img);
    });
  }

  // フォームがあるページのみ
  if ($(".js-page-has-form").length !== 0) {
    selectPlaceholder();
    formUploadFile();
    formScrollToError();
  }

  if ($(".js-page-entry").length !== 0) {
    entryFormSelect();
  }

  // lenis
  // const lenis = new Lenis();

  // function raf(time) {
  //   lenis.raf(time);
  //   requestAnimationFrame(raf);
  // }

  // requestAnimationFrame(raf);
});
