export const src = `
    precision highp float;

    struct Texture {
        sampler2D data;
        vec2 uvScale;
    };

    varying vec2 vUv;
    uniform float uMosaicPower;

    uniform vec3 uColor;
        
    uniform Texture uTexture;
    uniform float uPictureOpacity;

    vec4 getTexture(Texture tex, float scale,vec2 _uv) {
        vec2 uv = (_uv - vec2(0.5)) * scale * tex.uvScale + vec2(0.5);        
        uv = floor(uv * uMosaicPower * 10.0) / 10.0 / uMosaicPower;
        return texture2D(tex.data,uv);
    }

    void main() {
        vec3 pictureColor = getTexture(uTexture, 1., vUv).rgb;
        vec3 baseColor = uColor;
        vec3 color = mix(baseColor, pictureColor,uPictureOpacity);
        gl_FragColor = vec4(color, 1.);
    }
`;