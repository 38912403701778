import * as THREE from 'three';

// cameraObj = {
//     camera: THREE.Camerar
// .   resize: function() {}
// }


function createPerspectiveCamera(width,height,fov = 60) {
    const fovRad = (fov / 2) * (Math.PI / 180);        
    const dist = height / 2 / Math.tan(fovRad);
    const camera = new THREE.PerspectiveCamera(fov, width / height, 0.1, dist * 2);
    camera.position.z = dist;
    return {
        camera: camera,
        resize: function(width,height) {
            const fovRad = (this.camera.fov / 2) * (Math.PI / 180);
            this.camera.position.z = height / 2 / Math.tan(fovRad);
            this.camera.updateProjectionMatrix();            
        }
    }
}

function createOrthographicCamera(width,height) {
    const camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 0.1, 3000);
    camera.position.z = 1000;
    return {
        camera: camera,
        resize: function(width,height) {
            this.camera.left = width / -2;
            this.camera.right = width / 2;
            this.camera.top = height / 2;
            this.camera.bottom = height / -2;
            this.camera.updateProjectionMatrix();
        }
    }
}

export {createPerspectiveCamera,createOrthographicCamera};