import $ from "jquery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

export const careersBgText = () => {
  const trigger = document.querySelector(".js-bg-text-trigger");
  const target = document.querySelector(".js-bg-text-target");

  const timeline = gsap.fromTo(
    target,
    {
      x: "8%",
      y: "0%",
    },
    {
      scrollTrigger: {
        trigger: trigger,
        start: "top top",
        pin: target,
        end: "bottom top",
        endTrigger: trigger,
        ease: "none",
        scrub: 1,
      },
      x: "-150%",
      y: "30%",
    }
  );
};
