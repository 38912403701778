import $ from "jquery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import CanvasController from "./canvas/topMvCanvas/canvasController";

function isMobile() {
  return window.matchMedia("(max-width: 768px)").matches;
}

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

const CONFIG_DEBUG = true; // debug mode

export const topPageMv = () => {
  const section = $(".js-top-mv");
  const canvas = $(".js-top-mv-canvas");
  const bg = $(".js-top-mv-bg");

  const text01 = $(".js-top-mv-text-01");
  const text02 = $(".js-top-mv-text-02");
  const bottomText = $(".js-top-mv-bottom-text");

  const section1 = $(".js-top-mv-section-1");
  const section2 = $(".js-top-mv-section-2");
  const section3 = $(".js-top-mv-section-3");
  const section4 = $(".js-top-mv-section-4");

  let picSrc = canvas.data("pic-src");
  let textSrc = canvas.data("text-src");
  let textBgSrc = canvas.data("text-bg-src");

  if (isMobile()) {
    picSrc = canvas.data("pic-sp-src");
    textSrc = canvas.data("text-sp-src");
    textBgSrc = canvas.data("text-bg-sp-src");
  }

  if (CONFIG_DEBUG) {
    section.addClass("is-debug");
  }

  gsap.to($(text01), {
    ease: "power2.inOut",
    xPercent: -100,
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "+=250",
      scrub: 2,
      // pin: section,
    },
  });

  if (isMobile()) {
    gsap.to($(text02), {
      ease: "power2.inOut",
      yPercent: 300,
      scrollTrigger: {
        trigger: section,
        start: "top top",
        end: "+=250",
        scrub: 2,
        // pin: section,
      },
    });
  } else {
    gsap.to($(text02), {
      ease: "power2.inOut",
      yPercent: -300,
      scrollTrigger: {
        trigger: section,
        start: "top top",
        end: "+=250",
        scrub: 2,
        // pin: section,
      },
    });
  }

  gsap.to($(bottomText), {
    ease: "power2.inOut",
    yPercent: 500,
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "+=250",
      scrub: 2,
      // pin: section,
    },
  });

  const canvasController = new CanvasController({
    el: canvas[0],
    isGui: false,
  });

  setTimeout(() => {
    (async () => {
      await canvasController.setup({
        picSrc: picSrc,
        textSrc: textSrc,
        textBgSrc: textBgSrc,
      });
      canvasController.play();
    })();
  });

  // sticky canvas
  const canvasAnim = ScrollTrigger.create({
    trigger: section,
    start: "top top",
    end: "bottom bottom",
    scrub: true,
    pin: canvas,
  });

  const bgAnim = ScrollTrigger.create({
    trigger: section,
    start: "top top",
    end: "bottom bottom",
    scrub: true,
    pin: bg,
  });

  const sectionAnim1 = ScrollTrigger.create({
    trigger: section1,
    start: "top -200px",
    end: "bottom bottom",
    // markers: true,
    onUpdate: (self) => {
      const progress = self.progress.toFixed(2);
      canvasController.scroll(progress);
    },
  });

  let anim2 = null;
  const sectionAnim2 = ScrollTrigger.create({
    trigger: section2,
    start: "top bottom",
    end: "bottom bottom",
    // markers: true,
    onEnter: () => {
      if (anim2) anim2.kill();
      anim2 = gsap.timeline();
      anim2
        .set(bg, {
          opacity: 1,
        })
        .to(canvas, {
          opacity: 0,
          duration: 0.5,
          ease: "sine.out",
          onComplete: () => {
            section.addClass("is-scrolled");
          }          
        })
        .to(".js-top-mv-bg-1", {
          scale: 0.8,
          rotate: -2,
          duration: 0.5,
          ease: "sine.out",
        });
      anim2.to(
        gsap.utils.toArray(".js-top-mv-text-1"),
        {
          opacity: 1,
          duration: 0.3,
          ease: "sine.out",
        },
        0.3
      );
    },
    onLeaveBack: () => {
      if (anim2) anim2.kill();
      anim2 = gsap.timeline();
      anim2
        .to(".js-top-mv-bg-1", {
          scale: 1,
          rotate: 0,
          duration: 0.5,
          ease: "sine.out",
          onStart: () => {
            section.removeClass("is-scrolled");
          }
        })
        .to(canvas, {
          opacity: 1,
          duration: .1,
          ease: "sine.out",
        })
        .set(bg, {
          opacity: 0,
        });
      anim2.to(
        gsap.utils.toArray(".js-top-mv-text-1"),
        {
          opacity: 0,
          duration: 0.3,
          ease: "sine.out",
        },
        0.3
      );
    },
  });

  let anim3 = null;
  const sectionanim3 = ScrollTrigger.create({
    trigger: section3,
    start: "top bottom",
    end: "bottom bottom",
    onEnter: () => {
      if (anim3) anim3.kill();
      anim3 = gsap.timeline();
      anim3.to(gsap.utils.toArray(".js-top-mv-bg-2"), {
        scale: 0.8,
        rotate: -4,
        opacity: 1,
        duration: 0.5,
        ease: "sine.out",
      });
      anim3.to(
        gsap.utils.toArray(".js-top-mv-text-2"),
        {
          opacity: 1,
          duration: 0.3,
          ease: "sine.out",
        },
        0.3
      );
    },
    onLeaveBack: () => {
      if (anim3) anim3.kill();
      anim3 = gsap.timeline();
      anim3.to(gsap.utils.toArray(".js-top-mv-bg-2"), {
        scale: 1,
        rotate: 0,
        opacity: 0,
        duration: 0.5,
        ease: "sine.out",
      });
      anim3.to(
        gsap.utils.toArray(".js-top-mv-text-2"),
        {
          opacity: 0,
          duration: 0.3,
          ease: "sine.out",
        },
        0.3
      );
    },
  });

  let anim4 = null;
  const sectionanim4 = ScrollTrigger.create({
    trigger: section4,
    start: "top bottom",
    end: "bottom bottom",
    // markers: true,
    onEnter: () => {
      if (anim4) anim4.kill();
      anim4 = gsap.timeline();
      anim4.to(".js-top-mv-bg-3", {
        scale: 0.8,
        rotate: -6,
        opacity: 1,
        duration: 0.5,
        ease: "sine.out",
      });
      anim4.to(
        gsap.utils.toArray(".js-top-mv-text-3"),
        {
          opacity: 1,
          duration: 0.3,
          ease: "sine.out",
        },
        0.3
      );
    },
    onLeaveBack: () => {
      if (anim4) anim4.kill();
      anim4 = gsap.timeline();
      anim4.to(".js-top-mv-bg-3", {
        scale: 1,
        rotate: 0,
        opacity: 0,
        duration: 0.5,
        ease: "sine.out",
      });
      anim4.to(
        gsap.utils.toArray(".js-top-mv-text-3"),
        {
          opacity: 0,
          duration: 0.3,
          ease: "sine.out",
        },
        0.3
      );
    },
  });
};
