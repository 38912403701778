import gsap from "gsap";

export const infiniteScroll = () => {
  const wrap = gsap.utils.toArray(".js-infinite-scroll");

  wrap.forEach((el) => {
    const scrollEls = el.querySelectorAll(".js-infinite-scroll-el");
    const duration = el.dataset.duration;

    const tween = gsap
      .to(scrollEls, {
        xPercent: -100,
        repeat: -1,
        duration: duration,
        ease: "linear",
      })
      .totalProgress(0.5);
  });
};
