import * as THREE from 'three';

async function loadTexture(src,name = '') {
    const loader = new THREE.TextureLoader();
    const texture = await loader.loadAsync(src);
    texture.name = name;
    return texture;
}

function isMobile() {
    return window.matchMedia('(max-width: 768px)').matches;
}

export { loadTexture, isMobile};