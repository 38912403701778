import $ from "jquery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

function isMobile() {
  return window.matchMedia("(max-width: 768px)").matches;
}

export const careersMv = () => {
  const section = $(".js-sub-mv");
  const preText = $(".js-sub-mv-pre-text-wrap");
  const text01 = $(".js-pre-text-01");
  const text02 = $(".js-pre-text-02");
  const text03 = $(".js-pre-text-03");
  const bottomText = $(".js-pre-bottom-text");

  const overContent = $(".js-careers-mv-over-content");
  const overGroup = $(".js-careers-mv-over-group");

  gsap.to($(text01), {
    ease: "power2.inOut",
    xPercent: 200,
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "+=250",
      scrub: 2,
    },
  });

  if (isMobile()) {
    gsap.to($(text02), {
      ease: "power2.inOut",
      xPercent: 300,
      scrollTrigger: {
        trigger: section,
        start: "top top",
        end: "+=250",
        scrub: 2,
      },
    });
  } else {
    gsap.to($(text02), {
      ease: "power2.inOut",
      yPercent: 300,
      scrollTrigger: {
        trigger: section,
        start: "top top",
        end: "+=250",
        scrub: 2,
      },
    });
  }

  gsap.to($(text03), {
    ease: "power2.inOut",
    xPercent: -500,
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "+=250",
      scrub: 2,
    },
  });

  gsap.to($(bottomText), {
    ease: "power2.inOut",
    yPercent: 500,
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "+=250",
      scrub: 2,
    },
  });

  if (isMobile()) {
    gsap.to($(overContent), {
      ease: "power2.inOut",
      opacity: 1,
      scrollTrigger: {
        trigger: section,
        start: "bottom 200%",
        end: "bottom bottom",
        scrub: true,
      },
    });

    gsap.to($(overGroup), {
      ease: "power2.inOut",
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: section,
        start: "bottom 150%",
        end: "bottom bottom",
        scrub: true,
      },
    });
  } else {
    gsap.to($(overContent), {
      ease: "power2.inOut",
      opacity: 1,
      scrollTrigger: {
        trigger: section,
        start: "bottom 300%",
        end: "bottom bottom",
        scrub: true,
      },
    });

    gsap.to($(overGroup), {
      ease: "power2.inOut",
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: section,
        start: "bottom 200%",
        end: "bottom bottom",
        scrub: true,
      },
    });
  }
};
