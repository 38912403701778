import $ from "jquery";

export const entryFormSelect = () => {
  function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const jobTitleString = getParam("job-title");
  const select = $(`select[name='job-title']`);
  const options = $(select).children("option");

  options.each(function (i, option) {
    if (option.value === jobTitleString) {
      $(option).prop("selected", true);
    }
  });
};
