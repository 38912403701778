import Canvas from "./canvas";
import { isMobile } from "../webgl/canvasUtil";
// import * as dat from 'dat.gui';
// DOM操作とCanvasの管理を行う
class CanvasController {
    constructor(opt) {       

        this.el = opt.el;        
        this.isGui = opt.isGui;
        this.opt = opt;

        this.gui = null;
        this.canvas = null;
        
        this.isSetup = false;
        this.isSetupEnd = false;
        this.isPlay = false;
    }
    
    async setup({picSrc, textSrc,textBgSrc}) {
        if(this.isSetup) return;
        this.isSetup = true;        
        this.canvas = new Canvas(this.el,{            
            debug: false,
            fps: 60,
            alpha: true,
            clearAlpha: 0.0,
            clearColor: 0x000000,
            cameraType: 'orthographic', // orthographic or perspective
        });                
        await this.canvas.setup({
            picSrc: picSrc,      
            textSrc: textSrc,
            textBgSrc: textBgSrc
        });
        this.event();
        // this.setupGUI();
        this.isSetupEnd = true;
    }


    event() {
    }    

    setupGUI () {
    }

    play() {
        if(this.isPlay || !this.isSetupEnd) return;        
        this.isPlay = true;
        this.canvas.play();        
    }

    pause() {
        if(!this.isPlay) return;        
        this.isPlay = false;
        this.canvas.pause();
    }

    scroll(degree) {
        if(!this.isSetupEnd || !this.isPlay) return;
        this.canvas.internalPosition.target = {
            x: degree,
            y: degree,
        };
    }

    // other change function

    destroy() {
        if(!this.canvas) return;
        this.canvas.destroy();
        this.isSetup = false;
        this.isSetupEnd = false;
        this.canvas = null;        
    }
}

export default CanvasController;