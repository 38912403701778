import $ from "jquery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import CanvasController from "./canvas/subMvCanvas/canvasController";
import { isMobile } from "./canvas/webgl/canvasUtil";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

const CONFIG_DEBUG = false; // debug mode

export const subPageMv = () => {
  const section = $(".js-sub-mv");
  const canvas = $(".js-sub-mv-canvas");

  let picSrc = canvas.data("pic-src");
  let textSrc = canvas.data("text-src");
  if (isMobile()) {
    picSrc = canvas.data("pic-sp-src");
    textSrc = canvas.data("text-sp-src");
  }  

  if (CONFIG_DEBUG) {
    section.addClass("is-debug");
  }

  // $(window).on("load", () => {
  //   setTimeout(() => {
  //     preText.addClass("mv-is-loaded");
  //   }, 600);
  // });

  const canvasController = new CanvasController({
    el: canvas[0],
    isGui: false,
  });

  (async () => {
    gsap.set(canvas, {
      opacity: 0,
    })
    await canvasController.setup({
      picSrc: picSrc,
      textSrc: textSrc,
    });
    canvasController.play();
    gsap.to(canvas, {
      opacity: 1,
      delay: 0.1,
      duration: 0.6,
      ease: 'sin.out'
    });
  })();

  // sticky canvas
  const stickyObserver = ScrollTrigger.create({
    trigger: section,
    start: "top top",
    end: "bottom bottom",
    scrub: true,
    pin: canvas,
    markers: CONFIG_DEBUG,
  });

  const progressObserver = ScrollTrigger.create({
    trigger: section,
    start: "top -600px",
    end: "bottom bottom",
    onUpdate: (self) => {
      const progress = self.progress.toFixed(2);
      canvasController.scroll(progress);
    },
  });

  // const title = $(".js-sub-mv-title");
  // const titleAnim = gsap.to(title, {
  //   scale: 100,
  //   x: "-250%",
  //   ease: "power1.in",
  //   scrollTrigger: {
  //     trigger: section,
  //     start: "top top",
  //     end: "bottom top",
  //     scrub: true,
  //     pin: true,
  //     anticipatePin: 1,
  //     invalidateOnRefresh: true,
  //   },
  // });
};
