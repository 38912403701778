import $ from "jquery";

export const jobAccordion = () => {
  if ($(".js-accordion").length !== 0) {
    const triggers = $(".js-accordion-trigger");
    $(".js-accordion-content").hide();
    $(".js-accordion-content").eq(0).show();
    $(".js-accordion-content").eq(0).parent().addClass("is-open");

    triggers.on("click", function (e) {
      if (e.target.nodeName !== "A") {
        $(this).toggleClass("is-open");
        $(this).find(".js-accordion-content").slideToggle();
      }
    });
  }
};
