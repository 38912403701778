export const src = `
    precision highp float;

    struct Texture {
        sampler2D data;
        vec2 uvScale;
    };

    uniform vec2 uScreenCoord;
    uniform sampler2D uBg;

    varying vec2 vUv;
    uniform Texture uTexture;

    vec4 getTexture(Texture tex, float scale,vec2 _uv) {
        vec2 uv = (_uv - vec2(0.5)) * scale * tex.uvScale + vec2(0.5);    
        return texture2D(tex.data,uv);
    }

    void main() {
        vec2 uv = gl_FragCoord.xy / uScreenCoord.xy;
        vec4 text = getTexture(uTexture, 1., vUv).rgba;
        vec4 color = getTexture(uTexture, 1., vUv).rgba;

        if(text.a > 0.5) {
            color = texture2D(uBg, uv).rgba;
        } else {
            discard;
        }
        gl_FragColor = vec4(color);
    }
`;