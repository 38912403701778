import CanvasBase from "../webgl/canvasBase";
import LimitFrameRate from "../webgl/limitFrameRate";
// import { isMobile } from "../webgl/canvasUtil";
import * as THREE from 'three';

import InertiaPosition from "../lib/inertiaPosition";

import {src as vertShader} from '../glsl/subMvCanvas/vert';
import {src as fragShader} from '../glsl/subMvCanvas/frag';

import {src as textVertShader} from '../glsl/subMvCanvas/textVert';
import {src as textFragShader} from '../glsl/subMvCanvas/textFrag';
import { isMobile } from "../webgl/canvasUtil";

class Canvas extends CanvasBase {
    constructor(el,opt) {                
        super(el,opt);                   
        this.internalPosition = new InertiaPosition(0,0,{
            K: 0.05,
            D: 0.1
        });
    }
    createMesh() {
        this.rendererTarget = new THREE.WebGLRenderTarget(this.state.width,this.state.height);
        this.createPlane();
        this.createText();
        
    }

    createText() {
        const texture = this.text;

        let width = this.state.width;        
        let height = width / (1200 / 800);

        if(isMobile()) {
            width = this.state.width;
            height = width / (750 / 1624);
        }        
        

        const centerX = Number(this.el.dataset.centerX) || 0;
        const centerY = Number(this.el.dataset.centerY) || 0;            

        const g = new THREE.PlaneGeometry(1,1,1,1);
        const m = new THREE.ShaderMaterial({                        
            vertexShader: textVertShader,
            fragmentShader: textFragShader,
            transparent: true,
            uniforms: {
                uResolution: {
                    value: [
                        this.state.width,
                        this.state.height,
                    ]
                },
                uScreenCoord: { value: this.calcScreenCoord() },
                uTexture: {
                    value: {
                        data: texture,
                        uvScale: this.calcCoveredTextureScale(texture, width / height)
                    }
                },
                uBg: {
                    value: {
                        data: null,                        
                    },
                }

            }
        });    
        this.textWrap = new THREE.Group();
        this.textMesh = new THREE.Mesh(g,m);

        this.textMesh.scale.set(width,height,1);

        // modify position
        this.textMesh.position.set(
            width * centerX,
            height * centerY,
            0
        );
        this.textWrap.add(this.textMesh);        
        this.textWrap.position.set(
            width * -centerX,
            height * -centerY,
            0
        );                

        this.scene.add(this.textWrap);
    }

    createPlane() {
        // sample start
        const texture = this.texture;

        // const aspectRatio = 853 / 1280;
        const aspectRatio = (!isMobile()) ? (2880 / 1560) : (750 / 1624);
        let width = this.state.width;
        let height = width / aspectRatio;        
        
        if(this.state.height > height) {
            height = this.state.height;
            width = height * aspectRatio;
        }

        const g = new THREE.PlaneGeometry(1,1,1,1);        
        const m = new THREE.ShaderMaterial({            
            // side: THREE.DoubleSide,
            vertexShader: vertShader,
            fragmentShader: fragShader,
            uniforms: {                
                uColor: { value: new THREE.Color(0x171717) },
                uTexture: {
                    value: {
                        data: texture,
                        uvScale: this.calcCoveredTextureScale(texture, width / height)
                    }
                },
                uPictureOpacity: {
                    value: 0.0
                }
            }
        });                
        this.box = new THREE.Mesh(g,m);
        this.box.scale.set(width,height,1);
        this.scene.add(this.box);
    }

    update() {        
        const current = this.internalPosition.update();

        const degree = current.x;        
        const uPictureOpacity = Math.min(degree / 0.025,1)

        this.box.material.uniforms.uPictureOpacity.value = uPictureOpacity;


        const textScale = 1.0 + degree * 200;        
        this.textWrap.scale.set(textScale,textScale,1);

        this.textMesh.visible = false;
        this.box.visible = true;

        this.renderer.setRenderTarget(this.rendererTarget);
        this.render();

        this.textMesh.material.uniforms.uBg.value = this.rendererTarget.texture;
        this.renderer.setRenderTarget(null);

        this.textMesh.visible = true;
        this.box.visible = false;        
        
    }
    
    
    resizeScene() {
        super.resizeScene();


        const { width, height } = this.state;
        this.rendererTarget.setSize(width, height);

        // resize text
        {
            const texture = this.text;

            let width = this.state.width;        
            let height = width / (1200 / 800);
    
            if(isMobile()) {
                width = this.state.width;
                height = width / (750 / 1624);
            }            

            const centerX = Number(this.el.dataset.centerX) || 0;
            const centerY = Number(this.el.dataset.centerY) || 0;     
            
            this.textMesh.material.uniforms.uResolution.value = [
                this.state.width,
                this.state.height,
            ];
            this.textMesh.material.uniforms.uScreenCoord.value = this.calcScreenCoord();
            this.textMesh.material.uniforms.uTexture.value.uvScale = this.calcCoveredTextureScale(texture, width / height);
            
            this.textMesh.scale.set(width,height,1);

            this.textMesh.position.set(
                width * centerX,
                height * centerY,
                0
            );
            this.textWrap.position.set(
                width * -centerX,
                height * -centerY,
                0
            );                 

        }
        // resize plane
        {
            const texture = this.texture;

            const aspectRatio = (!isMobile()) ? (2880 / 1560) : (750 / 1624);
            let width = this.state.width;
            let height = width / aspectRatio;        
            
            if(this.state.height > height) {
                height = this.state.height;
                width = height * aspectRatio;
            }
                    
            this.box.material.uniforms.uTexture.value.uvScale = this.calcCoveredTextureScale(texture, width / height);
            this.box.position.set(0,0,0);            
            this.box.scale.set(width,height,1);

            
        }   
        

        // console.log('resizeScene');
    }


    async setup({picSrc, textSrc}) {
        super.setup();        
        if(!picSrc) throw new Error('picSrc is not defined');
        this.texture = await this.getTexture(picSrc); 
        if(!textSrc) throw new Error('textSrc is not defined');         
        this.text = await this.getTexture(textSrc);
        this.init();
    }  
    init() {
        this.createMesh();
    }
    frame(timestamp) {
        if(this.limitFrameRate.isLimitFrames(timestamp)) {
            this.frameObj.init();
            return;
        }        

        this.updateState();
        this.update();
        this.render();            

        this.frameObj.init();
    }
    play() {        
        const _this = this;
        if(!this.state.isSetup && this.state.isPlay) return; 
        this.state.isPlay = true;
        this.resizeScene();
        this.updateState();
        this.update();        

        this.limitFrameRate = new LimitFrameRate(this.opt.fps);

        this.frameObj = {
            id: null,
            init: function() {
                this.id = requestAnimationFrame(_this.frame.bind(_this));
            }
        }        
        
        this.frameObj.init();
    }
    pause() {
        if(!this.state.isPlay) return;
        this.state.isPlay = false;
        cancelAnimationFrame(this.frameObj.id);
        this.frameObj = null;
    }
    destroy() {
        this.pause();
        super.destroy();        
    }

    calcCoveredTextureScale(texture, aspect) {
        const result = new THREE.Vector2();
        const imageAspect = texture.source.data.width / texture.source.data.height;
        if (aspect < imageAspect) {
            result.set(aspect / imageAspect, 1)
        } else {
            result.set(1, imageAspect / aspect)
        }
        return [
            result.x,
            result.y
        ];
    }    
}

export default Canvas;