import $ from "jquery";
import Swiper from "swiper/bundle";

export const daySlider = () => {
  const cursor = $("#cursor");
  const cursorText = $(".js-cursor-text");
  const cursorEmoji = $(".js-cursor-emoji");

  const slider = new Swiper(".js-day-slider", {
    slidesPerView: 1.2,
    spaceBetween: 24,
    breakpoints: {
      769: {
        slidesPerView: 2.45,
        spaceBetween: 80,
      },
    },
    navigation: {
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    },
    on: {
      reachBeginning: function () {
        $("html, body").removeClass("is-cursor-disabled");
        cursor.removeClass("is-active is-day-slider-active");
        cursorText.text("");
        cursorEmoji.text("");
      },
      reachEnd: function () {
        $("html, body").removeClass("is-cursor-disabled");
        cursor.removeClass("is-active is-day-slider-active");
        cursorText.text("");
        cursorEmoji.text("");
      },
    },
  });
};
